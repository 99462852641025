import React from "react";
import { Layout } from "custom/layout";
import { MasterBusiness } from "./components/masterBusiness";
import { Benefits } from "./components/benefits";
import { ChooseFinext } from "./components/chooseFinext";

import { SendReceivePayments } from "./components/sendReceivePayments";

export const Businesses = () => {
  return (
    <Layout>
      <MasterBusiness />
      <Benefits />
      <SendReceivePayments />
      <ChooseFinext />
      <section className="pb-16 text-sm font-semibold">
        *Subject to compliance policy and approval of the terms & condition
      </section>
    </Layout>
  );
};
