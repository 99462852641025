const ExchangeRates = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.0091 0.75C17.7564 0.75 15.87 2.31808 15.3819 4.42223L2.04546 4.42224C1.33 4.42224 0.750008 5.00223 0.750008 5.71769C0.750008 6.43315 1.33 7.01314 2.04546 7.01314L15.5052 7.01314C16.137 8.8946 17.9148 10.25 20.0091 10.25C22.1034 10.25 23.8811 8.8946 24.513 7.01314H27.9545C28.67 7.01314 29.25 6.43315 29.25 5.71769C29.25 5.00223 28.67 4.42223 27.9545 4.42223H24.6363C24.1481 2.31808 22.2617 0.75 20.0091 0.75Z"
        fill="url(#paint0_linear_439_12)"
      />
      <path
        d="M7.91818 10.25C5.73339 10.25 3.89311 11.725 3.33887 13.7336L2.01152 13.7684C1.2963 13.7871 0.731706 14.3821 0.750454 15.0973C0.769201 15.8125 1.36419 16.3771 2.07941 16.3584L3.35543 16.3249C3.92899 18.3036 5.75467 19.75 7.91818 19.75C10.0693 19.75 11.8864 18.3201 12.4709 16.3589H27.9545C28.67 16.3589 29.25 15.779 29.25 15.0635C29.25 14.348 28.67 13.768 27.9545 13.768H12.5069C11.9643 11.7419 10.1155 10.25 7.91818 10.25Z"
        fill="url(#paint1_linear_439_12)"
      />
      <path
        d="M20.0091 19.75C17.868 19.75 16.0578 21.1666 15.4646 23.1136H2.04546C1.33 23.1136 0.750009 23.6936 0.750009 24.4091C0.750009 25.1245 1.33 25.7045 2.04546 25.7045H15.4132C15.9464 27.7446 17.8019 29.25 20.0091 29.25C22.2163 29.25 24.0718 27.7446 24.605 25.7045H27.9545C28.67 25.7045 29.25 25.1245 29.25 24.4091C29.25 23.6936 28.67 23.1136 27.9545 23.1136H24.5536C23.9604 21.1666 22.1502 19.75 20.0091 19.75Z"
        fill="url(#paint2_linear_439_12)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_439_12"
          x1="31.9633"
          y1="33.8774"
          x2="-5.89626"
          y2="-7.91715"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#01E285" />
          <stop offset="1" stopColor="#09CEAD" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_439_12"
          x1="31.9633"
          y1="33.8774"
          x2="-5.89626"
          y2="-7.91715"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#01E285" />
          <stop offset="1" stopColor="#09CEAD" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_439_12"
          x1="31.9633"
          y1="33.8774"
          x2="-5.89626"
          y2="-7.91715"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#01E285" />
          <stop offset="1" stopColor="#09CEAD" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default ExchangeRates;
