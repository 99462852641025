const FinextMainLogo = () => {
  return (
    <svg
      width="135"
      height="48"
      viewBox="0 0 135 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M47.7177 9.76765C47.7177 9.59498 47.7177 9.42242 47.7177 9.24976C47.578 8.62183 47.397 8.00383 47.1758 7.39981C46.3272 5.21927 44.8407 3.34617 42.911 2.02579C40.9813 0.705415 38.6986 -0.000684311 36.3613 4.97648e-07H11.603C8.52993 -6.43736e-06 5.58246 1.22058 3.40716 3.39399C1.23187 5.56739 0.00652456 8.51598 0 11.5929V36.4071C-3.44628e-06 37.9316 0.300105 39.441 0.883517 40.8492C1.46693 42.2573 2.32217 43.5364 3.39994 44.6132C4.47771 45.69 5.75675 46.5435 7.16436 47.1247C8.57197 47.7058 10.0804 48.0032 11.603 48H36.312C37.8334 48.0032 39.3402 47.7055 40.7464 47.1241C42.1526 46.5426 43.4308 45.6889 44.5066 44.6117C45.5824 43.5345 46.4351 42.2554 47.0158 40.8474C47.5966 39.4394 47.8936 37.9304 47.8903 36.4071V11.5929C47.8706 10.9815 47.8128 10.3719 47.7177 9.76765Z"
        fill="url(#paint0_linear_86_446)"
      />
      <path
        d="M47.7177 9.76765L44.9835 18.2773H23.7479C22.7429 18.2773 21.7482 18.079 20.82 17.6932C19.8919 17.3074 19.0486 16.7417 18.3391 16.029C17.6296 15.3163 17.0681 14.4707 16.6857 13.5401C16.3034 12.6096 16.1081 11.6124 16.1114 10.6062V7.39981H47.0777C47.2989 8.00383 47.4799 8.62183 47.6196 9.24976C47.6443 9.42242 47.693 9.59499 47.7177 9.76765Z"
        fill="#F7F7F7"
      />
      <path
        d="M38.0118 20.818L37.6671 21.8047L34.5138 31.1285H20.5952C20.3038 31.1272 20.0187 31.2165 19.7803 31.3844C19.5419 31.5524 19.362 31.7907 19.2649 32.0658L16.2841 40.2795L6.06022 40.5755H5.22241L9.58286 28.5384C10.4022 26.2717 11.8986 24.3125 13.8687 22.9269C15.8388 21.5412 18.1877 20.7962 20.5952 20.7933L38.0118 20.818Z"
        fill="#F7F7F7"
      />
      <path
        style={{ mixBlendMode: "multiply" }}
        d="M37.667 21.8047L34.5136 31.1285H20.5951C20.3036 31.1272 20.0185 31.2165 19.7801 31.3844C19.5418 31.5524 19.3618 31.7907 19.2647 32.0658L16.284 40.2795L6.06006 40.5755L10.0759 29.5252C10.9023 27.265 12.4011 25.3131 14.3702 23.9326C16.3392 22.552 18.6838 21.8093 21.0877 21.8047H37.667Z"
        fill="url(#paint1_linear_86_446)"
      />
      <path
        style={{ mixBlendMode: "multiply" }}
        d="M47.6196 9.24977L44.9588 17.1922H24.7336C23.7286 17.1922 22.7333 16.9935 21.8052 16.6077C20.877 16.2219 20.0343 15.6565 19.3248 14.9438C18.6153 14.2312 18.0532 13.3852 17.6709 12.4547C17.2885 11.5241 17.0932 10.5273 17.0965 9.52106V7.30106H47.0777C47.3031 7.93727 47.4841 8.58847 47.6196 9.24977V9.24977Z"
        fill="url(#paint2_linear_86_446)"
      />
      <path
        d="M28.1821 19.0421L28.404 13.7882C28.4092 13.6819 28.4457 13.5793 28.5087 13.4935C28.5716 13.4076 28.6579 13.3424 28.7577 13.3056C28.8575 13.2688 28.9663 13.2622 29.0698 13.2866C29.1733 13.311 29.267 13.3653 29.3398 13.4429L31.6313 15.9095L34.4395 18.9187C34.5418 19.0245 34.5988 19.1661 34.5988 19.3134C34.5988 19.4607 34.5418 19.6023 34.4395 19.7081L31.3601 22.446L28.8966 24.7154C28.8165 24.791 28.7154 24.8403 28.6067 24.8569C28.4979 24.8736 28.3869 24.8566 28.2879 24.8085C28.189 24.7603 28.1068 24.6831 28.0528 24.5871C27.9987 24.4912 27.9754 24.381 27.9854 24.2713L28.1821 19.0421Z"
        fill="url(#paint3_linear_86_446)"
      />
      <path
        d="M66.3666 20.9908H62.77V2.95981H75.3834V6.58564H66.3666V10.1871H71.7862V13.7882H66.3666V20.9908Z"
        fill="#231D56"
      />
      <path
        d="M81.1477 3.87245C81.1546 4.17741 81.0957 4.48025 80.9751 4.76039C80.8544 5.02614 80.6877 5.26843 80.4825 5.4758C80.268 5.68371 80.0175 5.85075 79.7433 5.969C79.4654 6.09821 79.1632 6.16572 78.8568 6.16652C78.5432 6.15945 78.2335 6.09228 77.945 5.969C77.6747 5.85696 77.4317 5.68895 77.2311 5.4758C77.0182 5.27491 76.8498 5.03101 76.7379 4.76039C76.6089 4.48214 76.5421 4.17922 76.5413 3.87245C76.5421 3.56567 76.6089 3.26275 76.7379 2.9845C76.8498 2.71388 77.0182 2.46998 77.2311 2.26909C77.4317 2.05594 77.6747 1.88793 77.945 1.77589C78.2335 1.65261 78.5432 1.58575 78.8568 1.57867C79.1632 1.57947 79.4654 1.64668 79.7433 1.77589C80.0175 1.89414 80.268 2.06118 80.4825 2.26909C80.6877 2.47646 80.8544 2.71875 80.9751 2.9845C81.0957 3.26464 81.1546 3.56748 81.1477 3.87245ZM80.5565 20.9908H77.1078V7.52297H80.5565V20.9908Z"
        fill="#231D56"
      />
      <path
        d="M86.8631 20.9909H83.4385V7.52302H84.2516L85.3847 8.83039C85.9376 8.33906 86.571 7.94696 87.257 7.67116C87.9572 7.40509 88.701 7.27119 89.4499 7.27642C90.2541 7.26746 91.0512 7.42726 91.7901 7.74523C92.5004 8.04384 93.145 8.47944 93.687 9.02761C94.2323 9.58155 94.6671 10.2346 94.9681 10.9516C95.303 11.7295 95.4626 12.5716 95.436 13.4182V21.114H92.012V13.4182C92.0226 13.0786 91.955 12.741 91.8147 12.4315C91.687 12.1211 91.5033 11.8369 91.2728 11.593C91.0341 11.3678 90.7591 11.1845 90.4597 11.0504C90.1421 10.9096 89.7971 10.8423 89.4499 10.8532C89.1031 10.847 88.7585 10.9142 88.4395 11.0504C88.1294 11.1783 87.8458 11.3622 87.6022 11.593C87.3718 11.8369 87.1881 12.1211 87.0604 12.4315C86.9315 12.7445 86.8644 13.0797 86.8631 13.4182V20.9909Z"
        fill="#231D56"
      />
      <path
        d="M103.492 17.6854H103.885H104.28C104.598 17.7366 104.922 17.7366 105.241 17.6854C105.553 17.5946 105.851 17.4621 106.128 17.291C106.399 17.1176 106.64 16.901 106.842 16.6497C107.058 16.4043 107.241 16.1302 107.384 15.8355L109.848 18.3021C109.547 18.7632 109.182 19.1786 108.763 19.5354C108.347 19.8898 107.893 20.1959 107.409 20.448C106.933 20.6986 106.428 20.8894 105.906 21.0156C105.365 21.1433 104.811 21.2094 104.255 21.2128C103.343 21.2174 102.439 21.0414 101.595 20.6949C100.781 20.3376 100.038 19.836 99.4023 19.2147C98.7714 18.5675 98.2695 17.8059 97.924 16.9703C97.571 16.0583 97.3954 15.087 97.4068 14.109C97.3958 13.1149 97.5712 12.1275 97.924 11.1983C98.2634 10.3594 98.766 9.59666 99.4023 8.95387C100.034 8.33339 100.778 7.83922 101.595 7.49835C102.439 7.15184 103.343 6.9759 104.255 6.98046C104.811 6.98388 105.365 7.04997 105.906 7.17768C106.433 7.32335 106.945 7.52195 107.433 7.76994C107.914 8.028 108.368 8.33373 108.788 8.68258C109.201 9.04525 109.565 9.45978 109.872 9.91588L103.492 17.6854ZM105.241 10.631C105.085 10.6116 104.928 10.6116 104.773 10.631H104.28C103.825 10.6245 103.372 10.7082 102.95 10.8776C102.549 11.0484 102.189 11.3005 101.891 11.6177C101.574 11.9548 101.324 12.3479 101.151 12.7769C100.976 13.2586 100.892 13.7689 100.905 14.2815V14.7256V15.1941C100.905 15.3668 100.905 15.515 100.905 15.663C100.947 15.8084 101.005 15.9491 101.077 16.0824L105.241 10.631Z"
        fill="#231D56"
      />
      <path
        d="M124.136 20.9908H119.948L117.263 16.8715L114.577 20.9908H110.39L115.317 14.0842L110.39 7.44919H114.577L117.263 11.3216L119.948 7.44919H124.136L119.209 14.0842L124.136 20.9908Z"
        fill="#231D56"
      />
      <path
        d="M132.389 20.9908C131.594 20.9876 130.807 20.8282 130.073 20.522C129.355 20.2122 128.703 19.7685 128.151 19.2146C127.604 18.6664 127.162 18.023 126.846 17.3156C126.526 16.5331 126.367 15.6943 126.378 14.849V10.8531H124.727V7.4245H126.378V2.04718H129.802V7.4245H135V10.8531H129.802V14.849C129.805 15.1953 129.872 15.5379 129.999 15.8601C130.124 16.1649 130.308 16.4419 130.541 16.6743C130.783 16.913 131.067 17.1052 131.379 17.2415C131.701 17.3685 132.043 17.4353 132.389 17.4388H135V20.8674L132.389 20.9908Z"
        fill="#231D56"
      />
      <path
        d="M76.9352 44.2259C76.1244 44.9354 75.1891 45.4875 74.1764 45.854C73.161 46.2372 72.0835 46.4295 70.9984 46.4212C70.1661 46.4326 69.3366 46.3246 68.5349 46.1006C67.7604 45.88 67.0154 45.5652 66.3174 45.1632C65.6493 44.7581 65.0294 44.2782 64.4697 43.7327C63.9039 43.1756 63.4154 42.5451 63.0167 41.8581C62.6237 41.158 62.3173 40.4124 62.1049 39.6381C61.876 38.8359 61.7602 38.0057 61.7603 37.1715C61.7602 36.3372 61.876 35.507 62.1049 34.7049C62.3166 33.9381 62.6231 33.2006 63.0167 32.5096C63.4154 31.8225 63.9039 31.1921 64.4697 30.6349C65.0294 30.0894 65.6493 29.6096 66.3174 29.2044C67.0154 28.8024 67.7604 28.4876 68.5349 28.2671C69.3366 28.043 70.1661 27.935 70.9984 27.9464C72.0825 27.9503 73.1578 28.1422 74.1764 28.5137C75.1891 28.8801 76.1244 29.4322 76.9352 30.1417L75.0876 33.2497C74.5545 32.7022 73.9181 32.2661 73.2153 31.967C72.5182 31.6539 71.7624 31.4942 70.9984 31.4985C70.2445 31.4907 69.4971 31.6418 68.8055 31.9423C68.1337 32.2251 67.5231 32.6355 67.0072 33.1509C66.4846 33.666 66.0663 34.2774 65.7755 34.9515C65.4775 35.6446 65.3267 36.3922 65.3322 37.1468C65.3268 37.9093 65.4776 38.665 65.7755 39.3668C66.0663 40.0408 66.4846 40.6522 67.0072 41.1673C67.5231 41.6827 68.1337 42.0932 68.8055 42.376C69.4971 42.6764 70.2445 42.8278 70.9984 42.8201C71.4378 42.8197 71.8758 42.7699 72.3041 42.6719C72.7191 42.5608 73.1231 42.4124 73.5112 42.2281V37.1468H76.9352V44.2259Z"
        fill="#231D56"
      />
      <path
        d="M82.7493 46.0512H79.3247V27.4039H82.7493V46.0512Z"
        fill="#231D56"
      />
      <path
        d="M98.1954 39.2681C98.209 40.2168 98.0239 41.1577 97.653 42.0307C97.3192 42.8666 96.8258 43.6292 96.1999 44.2754C95.585 44.8996 94.8575 45.4016 94.0563 45.7553C93.2145 46.1122 92.3103 46.2969 91.3962 46.2982C90.49 46.297 89.5932 46.1122 88.7601 45.7553C87.9513 45.4023 87.2156 44.9003 86.5919 44.2754C85.9659 43.6292 85.4725 42.8666 85.1388 42.0307C84.7679 41.1577 84.5833 40.2168 84.5969 39.2681C84.5836 38.3036 84.768 37.3465 85.1388 36.4562C85.4667 35.6172 85.9607 34.8539 86.5919 34.2118C87.2156 33.5869 87.9513 33.0849 88.7601 32.7319C89.5948 32.3828 90.4916 32.2064 91.3962 32.2137C92.307 32.1987 93.2113 32.3666 94.0563 32.7072C94.8601 33.0338 95.5896 33.5204 96.1999 34.1377C96.8304 34.7708 97.3246 35.5263 97.653 36.3577C98.0276 37.2815 98.2125 38.2713 98.1954 39.2681ZM94.7708 39.2681C94.7835 38.7556 94.6997 38.2452 94.5243 37.7635C94.3425 37.3469 94.0932 36.9635 93.7857 36.629C93.4827 36.3061 93.1122 36.0534 92.7013 35.8889C92.2877 35.7176 91.8437 35.6337 91.3962 35.6423C90.9488 35.6358 90.5047 35.7196 90.0904 35.8889C89.6868 36.0536 89.3245 36.3066 89.0307 36.629C88.7148 36.9521 88.4717 37.3388 88.3168 37.7635C88.1214 38.2403 88.0288 38.7531 88.0455 39.2681C88.0355 39.7587 88.1281 40.2461 88.3168 40.699C88.4717 41.1237 88.7148 41.5104 89.0307 41.8335C89.3236 42.163 89.6856 42.4242 90.0904 42.5983C90.4993 42.7871 90.946 42.8799 91.3962 42.8696C91.8437 42.8782 92.2877 42.794 92.7013 42.6227C93.1122 42.4582 93.4827 42.2058 93.7857 41.8829C94.0932 41.5484 94.3425 41.165 94.5243 40.7483C94.6978 40.2747 94.7816 39.7725 94.7708 39.2681Z"
        fill="#231D56"
      />
      <path
        d="M113.395 39.2681C113.407 40.2462 113.231 41.2174 112.878 42.1294C112.531 42.9511 112.039 43.7032 111.424 44.3494C110.801 44.9618 110.065 45.4475 109.257 45.78C108.414 46.131 107.509 46.3074 106.596 46.2982C105.692 46.3027 104.795 46.1264 103.96 45.78C103.15 45.4295 102.414 44.9272 101.792 44.3001C101.162 43.667 100.667 42.9115 100.339 42.0801C99.9659 41.1903 99.7814 40.2328 99.7968 39.2681V27.4039H103.221V33.6445C103.402 33.401 103.618 33.1849 103.861 33.0031C104.136 32.8308 104.424 32.6822 104.724 32.559C105.028 32.4427 105.341 32.352 105.66 32.2877H106.596C107.509 32.2785 108.414 32.4546 109.257 32.8056C110.072 33.1469 110.809 33.6507 111.424 34.2858C112.034 34.9359 112.526 35.6868 112.878 36.5055C113.222 37.3858 113.397 38.3229 113.395 39.2681ZM109.995 39.2681C109.996 38.7704 109.904 38.277 109.725 37.8129C109.552 37.3915 109.301 37.0067 108.986 36.6783C108.684 36.3477 108.314 36.0866 107.902 35.9136C107.49 35.7338 107.045 35.6414 106.596 35.6423C106.144 35.6405 105.698 35.7417 105.29 35.9382C104.879 36.1357 104.511 36.4125 104.207 36.7524C103.904 37.0948 103.662 37.4869 103.492 37.9116C103.325 38.3442 103.241 38.8044 103.245 39.2681C103.239 39.7561 103.323 40.2412 103.492 40.6989C103.656 41.1191 103.899 41.5041 104.207 41.8335C104.513 42.1586 104.881 42.4188 105.29 42.5982C105.699 42.7871 106.146 42.8798 106.596 42.8695C107.046 42.8825 107.494 42.7896 107.902 42.5982C108.314 42.4252 108.684 42.1641 108.986 41.8335C109.301 41.5051 109.552 41.1203 109.725 40.6989C109.902 40.2427 109.993 39.7576 109.995 39.2681Z"
        fill="#231D56"
      />
      <path
        d="M128.176 46.0513H127.338L126.032 44.2013C125.704 44.5002 125.358 44.7804 124.998 45.0402C124.643 45.2921 124.263 45.507 123.865 45.6815C123.473 45.8767 123.059 46.0255 122.633 46.1253C122.225 46.2432 121.802 46.3015 121.377 46.2982C120.47 46.2949 119.57 46.1363 118.716 45.8293C117.918 45.5144 117.19 45.0452 116.573 44.4482C115.937 43.8143 115.435 43.0595 115.095 42.2282C114.735 41.2837 114.56 40.2788 114.577 39.2681C114.566 38.29 114.742 37.3191 115.095 36.4071C115.44 35.5715 115.942 34.8095 116.573 34.1624C117.186 33.5428 117.914 33.0487 118.716 32.7072C119.565 32.3802 120.467 32.2128 121.377 32.2137C121.808 32.2142 122.238 32.264 122.658 32.3618C123.079 32.4768 123.491 32.6251 123.89 32.8056C124.286 33.0029 124.665 33.2341 125.023 33.4963C125.376 33.7464 125.713 34.0182 126.032 34.3102L127.338 32.7318H128.176V46.0513ZM124.751 39.2681C124.747 38.8029 124.655 38.3428 124.481 37.9117C124.311 37.4869 124.069 37.0948 123.766 36.7524C123.462 36.4125 123.094 36.1357 122.682 35.9382C122.275 35.7417 121.829 35.6405 121.377 35.6423C120.924 35.6397 120.474 35.7147 120.046 35.8642C119.647 36.0265 119.287 36.2702 118.987 36.5796C118.669 36.9007 118.425 37.288 118.273 37.7141C118.087 38.2108 117.994 38.7378 118.001 39.2681C117.996 39.7903 118.089 40.3088 118.273 40.7974C118.431 41.2278 118.674 41.6222 118.987 41.9569C119.288 42.2591 119.649 42.4942 120.046 42.6473C120.474 42.7968 120.924 42.8721 121.377 42.8695C121.829 42.8713 122.275 42.7701 122.682 42.5736C123.094 42.3761 123.462 42.0993 123.766 41.7594C124.074 41.4209 124.317 41.0278 124.481 40.6002C124.655 40.1776 124.747 39.7254 124.751 39.2681Z"
        fill="#231D56"
      />
      <path
        d="M134.162 46.0512H130.763V27.4039H134.162V46.0512Z"
        fill="#231D56"
      />
      <defs>
        <linearGradient
          id="paint0_linear_86_446"
          x1="-0.0985398"
          y1="24"
          x2="47.8411"
          y2="24"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#16C37B" />
          <stop offset="1" stopColor="#17BDA1" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_86_446"
          x1="27.0246"
          y1="39.8355"
          x2="15.3507"
          y2="20.3376"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.34" stopColor="white" stopOpacity="0" />
          <stop offset="1" stopOpacity="0.2" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_86_446"
          x1="38.7755"
          y1="8.55903"
          x2="18.4665"
          y2="18.3563"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.34" stopColor="white" stopOpacity="0" />
          <stop offset="0.96" stopOpacity="0.2" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_86_446"
          x1="27.9361"
          y1="19.0669"
          x2="34.5871"
          y2="19.0669"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#16C37B" />
          <stop offset="1" stopColor="#17BDA1" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default FinextMainLogo;
