import React, { FC, ReactNode } from "react";
import styles from "./index.module.scss";
import { ModalSubmit } from "custom/modal/modalSubmit";

type ContentTemplateProps = {
  title: string;
  paragraph: string;
};
export const ContentTemplate: FC<ContentTemplateProps> = ({
  title,
  paragraph,
}) => {
  return (
    <div className={`${styles["content-cont"]} `}>
      <div>
        <h1>{title}</h1>
        <p
          className="pt-6 font-semibold text-lg"
          dangerouslySetInnerHTML={{ __html: paragraph }}
        />
        <div className={`${styles["button-cont"]} pt-10`}>
          <ModalSubmit buttonText="Get started" />
        </div>
      </div>
    </div>
  );
};
