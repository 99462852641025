import React, { useEffect } from "react";
import styles from "./index.module.scss";

import {
  AccessAnywhere,
  ExchangeRates,
  AssetsSafeguard,
  FreeAccount,
  UserFriendliness,
} from "assets/icons";
import Aos from "aos";

const benefitsItems = [
  { id: 1, text: "Free Account with No Fees", image: FreeAccount },
  { id: 2, text: "User Friendliness", image: UserFriendliness },
  { id: 3, text: "Access Anywhere", image: AccessAnywhere },
  { id: 4, text: "Safeguard Your Assets", image: AssetsSafeguard },
  { id: 5, text: "Competitive Exchange Rates", image: ExchangeRates },
];

export const Benefits = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <section
      className={`${styles["benefits-cont"]} flex flex-wrap justify-around py-16 md:py-28`}
    >
      {benefitsItems.map((benefit) => (
        <div
          key={benefit.id}
          className={`${styles["benefits-item"]} flex justify-center`}
          data-aos={"fade-up"}
        >
          <div>
            <div className={styles["benefits-item-img"]}>
              <benefit.image />
            </div>
            <span>{benefit.text}</span>
          </div>
        </div>
      ))}
    </section>
  );
};
