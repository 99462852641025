import React, { FC } from "react";
import styles from "./index.module.scss";
import { NavLink } from "react-router-dom";
import { FinextMainLogo } from "assets/icons";
import { ModalSubmit } from "../modal/modalSubmit";

type propsLayout = { children: React.ReactNode };

export const Layout: FC<propsLayout> = ({ children }) => {
  return (
    <div className={styles.layout}>
      <section className={`${styles.header} flex justify-between `}>
        <NavLink to="/">
          <div className={`${styles["logo-cont"]} pr-1 mr-4`}>
            <FinextMainLogo />
          </div>
        </NavLink>

        <div className={`${styles["navbar-cont"]}`}>
          <ul>
            <NavLink
              to="/"
              className={(props) => (props.isActive ? `${styles.active}` : "")}
            >
              <li className="inline text-disabled">For Business</li>
            </NavLink>
          </ul>
        </div>
        <ModalSubmit buttonText="Start For Free" />
      </section>
      {children}
    </div>
  );
};
