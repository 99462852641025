import React from "react";
import { ChooseFinextWrapper } from "custom/chooseFinextWrapper";
import styles from "./index.module.scss";
import { ChooseItem } from "custom/chooseItem";
import {
  InterfaceErp,
  ListenClients,
  FinextLogo,
  MultipleTransactions,
  SendReceiveCrypto,
} from "assets/icons";
import { ModalSubmit } from "custom/modal/modalSubmit";

const benefitsIcons = [
  {
    id: 1,
    icon: ListenClients,
    text: "We listen to our clients 24/7",
  },
  {
    id: 2,
    icon: SendReceiveCrypto,
    text: "Convert, send and receive crypto or fiat as you wish",
  },
  {
    id: 3,
    icon: MultipleTransactions,
    text: "Managing multiple transactions of different types",
  },
  {
    id: 4,
    icon: InterfaceErp,
    text: "Interfacing for any ERP",
  },
];
export const ChooseFinext = () => {
  return (
    <ChooseFinextWrapper>
      <div className="flex flex-wrap bg-white justify-between relative">
        {benefitsIcons.map((benefitsIcon) => (
          <div
            key={benefitsIcon.id}
            className={`${styles["choose-item"]} bg-white`}
            data-aos={
              benefitsIcon.id === 1 || benefitsIcon.id === 3
                ? "fade-right"
                : "fade-left"
            }
          >
            <ChooseItem text={benefitsIcon.text}>
              <benefitsIcon.icon />
            </ChooseItem>
          </div>
        ))}
        <div className={styles["abs-cont"]}>
          <div className={styles["icon-cont"]}>
            <FinextLogo />
            <h4>Finext Managment System</h4>
          </div>
        </div>
      </div>
      <div className={styles["button-cont"]} data-aos="fade-up">
        <ModalSubmit buttonText="Get Started" />
      </div>
    </ChooseFinextWrapper>
  );
};
