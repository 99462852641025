import React, { useEffect } from "react";
import styles from "./index.module.scss";
import { balanceChart, invoices, receiveFrom, sendTo } from "assets/images";
import { ContentTemplate } from "custom/contentTemplate";
import Aos from "aos";

export const SendReceivePayments = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <section
      className={`${styles["send-receive-payments"]} flex flex-wrap justify-between`}
    >
      <div className={styles["image-cont"]}>
        <img src={balanceChart} alt="balance" />
        <div className={styles["img-1-cont"]} data-aos="fade-left">
          <img src={invoices} alt="invoices" />
        </div>
        <div className={styles["img-2-cont"]} data-aos="fade-left">
          <img src={sendTo} alt="send to" />
        </div>
        <div className={styles["img-3-cont"]} data-aos="fade-right">
          <img src={receiveFrom} alt="receive from" />
        </div>
      </div>
      <ContentTemplate
        title="Send and Receive Payments Easily and Fast"
        paragraph="Your beneficiary will be able to confirm and receive the payment within minutes. "
      />
    </section>
  );
};
