const Lines = () => {
  return (
    <svg
      width="102"
      height="127"
      viewBox="0 0 102 127"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.2596 23.9401C19.4505 15.5526 24.5878 7.77718 30.0172 0.209725C30.6376 0.369192 30.0925 0.755642 30.3137 0.999606C30.6654 1.16197 31.0568 0.314518 31.5091 1.06836C31.925 1.76091 31.6785 2.28156 31.3857 2.81878C29.8444 5.64653 27.6185 8.03481 26.2753 11.0171C25.8155 12.0368 25.6146 13.2856 24.4809 14.0801C23.783 14.5688 23.9474 15.8048 23.3307 16.6187C22.9447 17.1286 22.7301 17.8419 22.3006 18.4172C20.5891 20.7077 19.6029 23.2985 19.3217 26.1462C19.2711 26.659 19.0574 27.1233 18.5819 27.139C17.2854 27.1825 15.9977 27.7197 14.7269 26.5852C13.8045 25.7614 13.7188 25.0154 13.9797 24.0222C14.6089 21.6293 15.8569 19.5177 17.0817 17.4078C18.5144 14.94 20.1414 12.5885 21.659 10.1754C23.7002 6.92972 25.9853 3.86464 28.1528 0.713808C28.3591 0.41434 28.5567 -0.204891 29.009 0.0676538C29.3495 0.272683 28.9908 0.763098 28.7965 1.06132C27.5713 2.9418 26.2595 4.75848 24.9159 6.55943C23.6281 8.28541 22.5487 10.1762 21.4734 12.0493C19.7359 15.0771 17.8214 18.0034 16.2491 21.1256C15.8009 22.0158 15.3573 22.9175 15.2596 23.9401Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.63551 60.1375C7.84812 60.7704 7.27859 59.7855 7.02966 59.5854C6.35617 59.0445 5.30037 59.6024 5.03735 58.962C4.6393 57.9924 3.78066 57.1719 4.13688 55.8729C4.82528 53.3604 5.86865 50.9953 6.83871 48.5966C7.54202 46.8578 8.2122 45.102 9.23942 43.5123C9.69753 42.8036 10.224 42.1786 11.0014 41.8224C11.7988 41.4566 12.4988 41.4306 13.2667 42.0879C13.835 42.5742 14.5677 42.8935 15.0937 43.4651C15.5945 44.0085 15.9921 44.5772 15.4015 45.3178C14.9869 45.8372 15.0618 46.5182 14.8531 47.1105C14.0139 49.4905 13.6946 52.0536 12.3484 54.2542C11.3502 55.8866 10.6125 57.6432 9.84291 59.3862C9.62256 59.8853 9.40924 60.4884 8.63551 60.1375Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M58.2784 42.313C58.0933 42.7305 57.974 42.9989 57.8588 43.2582C57.9955 43.4367 58.1218 43.3303 58.2577 43.2922C58.5365 43.2135 58.6321 42.6796 58.9697 42.8929C59.3781 43.1509 58.8529 43.4599 58.8844 43.7656C59.3313 43.8696 59.4502 43.3174 59.8147 43.3021C60.3797 44.1711 60.0831 44.8707 59.5571 45.6502C58.1276 47.7684 56.8888 49.9948 56.0546 52.4311C55.7398 53.351 54.5614 53.8004 54.5386 54.8683C54.5096 56.2227 53.8775 57.3986 53.1672 58.367C51.9403 60.0396 51.6781 62.0074 50.9479 63.8254C50.7424 64.3369 51.0241 64.9669 50.5188 65.3861C50.3556 65.5215 50.0992 65.8301 49.9927 65.57C49.6274 64.6811 48.9154 65.1094 48.326 64.9619C47.2321 64.6881 47.0167 63.6154 46.9098 62.6718C46.7417 61.1848 47.1708 59.7467 47.7743 58.4213C50.0839 53.3461 52.77 48.4771 56.0931 43.9859C56.5992 43.3017 57.0532 42.443 58.2784 42.313Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.9749 22.1607C42.7214 22.9505 42.4766 23.7773 42.1917 24.5904C40.387 29.7447 38.0459 34.6575 35.5102 39.4854C34.5381 41.3361 33.4177 43.1122 32.682 45.0871C32.4716 45.6529 32.0934 46.0994 31.4187 45.4976C31.2597 45.3555 31.0236 45.2913 30.8132 45.2167C29.4136 44.7214 28.8668 43.6486 29.3059 42.1927C29.7557 40.6991 30.5878 39.391 31.3943 38.0796C34.8065 32.5331 38.0161 26.8821 40.4661 20.8298C40.7768 20.0627 40.8666 20.0636 41.4113 20.6476C41.4706 20.7109 41.5907 20.7569 41.6768 20.7491C42.6929 20.6583 42.7852 21.4031 42.9749 22.1607Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.76018 80.0504C7.82989 82.3397 6.30894 84.0918 5.42255 86.1649C4.52663 88.2603 3.44184 90.2804 2.87936 92.517C3.69492 90.724 4.50592 88.9284 5.32687 87.1374C6.26959 85.0825 7.46498 83.1735 8.64007 81.2466C9.22989 80.2799 9.8922 80.3292 10.5562 80.8614C11.2044 81.3817 11.339 82.2577 10.7285 82.8682C9.48462 84.1121 8.78504 85.7014 8.33066 87.2625C7.53953 89.9813 6.14865 92.5013 5.72947 95.3407C5.63296 95.9951 5.15953 96.553 5.0622 97.282C4.95285 98.1021 4.91018 98.8046 5.31693 99.5688C5.55261 100.013 6.13415 100.597 5.61764 101.092C5.19515 101.498 4.57634 101.795 3.8722 101.426C3.60338 101.285 3.21154 101.377 2.87563 101.366C0.911485 101.299 -0.247864 100.019 0.0449764 98.0383C0.437639 95.3817 1.43545 92.8969 2.48918 90.4431C3.86971 87.2277 5.37078 84.0744 7.41403 81.2106C7.71557 80.7881 7.96823 80.3213 8.76018 80.0504Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M62.3136 105.105C59.6536 105.099 58.8521 103.935 59.7522 101.729C60.4559 100.004 61.6529 98.5827 62.8086 97.1587C65.5063 93.8351 68.3141 90.6068 71.3034 87.5355C72.0345 86.7842 72.9221 86.5153 73.8334 86.2366C74.1515 86.1397 74.8606 85.9483 74.8337 86.2254C74.7773 86.792 75.346 87.3069 74.8341 87.9133C72.5477 90.6209 70.5073 93.5195 68.3932 96.3609C67.4613 97.6139 66.4299 98.7952 65.5324 100.071C64.9255 100.935 64.3051 101.867 64.9206 103.03C64.9943 103.168 64.9562 103.532 64.8908 103.554C63.8403 103.904 63.2054 105.023 62.3136 105.105Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M89.5047 44.8725C90.8708 43.3379 91.7207 41.4715 93.0677 39.9464C93.4645 39.4974 93.94 39.1077 94.2792 38.6206C94.8392 37.8158 95.4154 38.4292 95.7857 38.6827C96.1452 38.9283 96.3138 39.4991 95.9282 40.0392C94.3534 42.2448 93.0867 44.6592 92.0231 47.1162C91.2651 48.8679 90.0067 50.4021 89.7441 52.4404C89.6443 53.212 89.117 54.1026 88.8246 54.9666C88.4336 56.1218 87.6305 55.1654 87.0108 55.1232C86.9131 55.1165 86.8265 54.8291 86.737 54.8307C86.3556 54.8361 86.1311 55.8074 85.6841 55.1335C85.0777 54.219 84.4304 53.3562 84.8545 52.0042C85.5284 49.8583 86.5635 47.9223 87.6963 46.0223C89.2322 43.4464 90.6488 40.7798 92.8345 38.6541C92.9956 38.4975 93.1435 38.3257 93.3158 38.1823C93.4935 38.0345 93.7325 37.9081 93.923 38.09C94.1653 38.3215 94.0369 38.6015 93.8248 38.7755C91.9713 40.2931 91.048 42.4822 89.7491 44.3991C89.6505 44.5449 89.5855 44.7143 89.5047 44.8725Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.7827 82.9959C44.3543 82.4566 44.9996 81.9753 45.478 81.3636C46.0364 80.6499 46.6581 80.4933 47.4447 80.8044C47.9123 80.99 47.8129 81.4804 47.7678 81.7931C47.6514 82.5979 47.175 83.2179 46.3972 83.5046C46.2112 83.5729 45.9312 83.4321 45.9316 83.7684C45.9357 85.4115 44.4947 86.2863 43.8755 87.6027C43.6854 88.0061 43.3312 88.3892 43.0819 88.803C42.3537 90.0113 41.7581 91.3239 40.2148 91.7865C39.8553 91.8942 39.2853 92.216 39.4796 92.7624C40.0723 94.4287 38.9051 95.5417 38.2428 96.7772C37.1543 98.8072 35.7733 100.672 34.7639 102.752C34.65 102.986 34.5796 103.32 34.2524 103.238C33.8436 103.136 34.3853 102.774 34.1016 102.56C33.5921 102.831 33.1448 103.564 32.4344 102.699C32.1809 102.391 31.8086 102.912 31.6263 103.24C31.4876 103.489 31.3351 103.723 31.0224 103.54C30.6443 103.319 30.9702 103.087 31.0701 102.884C32.8892 99.1763 35.1363 95.7285 37.4682 92.332C39.5218 89.3407 41.575 86.3489 43.6282 83.3584C43.6792 83.2382 43.7309 83.1173 43.7827 82.9959Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M63.6668 69.8361C64.926 67.8877 66.1947 65.9418 67.596 64.0845C65.9975 65.2832 64.0707 68.0649 63.6668 69.8361ZM66.1309 72.7936C65.7391 72.9079 66.7382 71.546 65.6703 72.3823C65.6418 72.4047 65.5854 72.4391 65.5726 72.4291C64.8689 71.8828 64.2492 71.9134 63.6051 72.5811C63.4361 72.7563 62.1248 71.2867 62.0966 70.8527C62.0544 70.1974 62.2201 69.5769 62.5083 69.0033C64.1353 65.7634 66.4242 63.0715 69.3161 60.8932C70.7327 59.8258 72.2189 59.9422 73.7021 60.737C74.4986 61.1641 74.4949 61.6773 73.8326 62.3164C73.1355 62.9886 72.4724 63.7015 71.8482 64.4421C71.1279 65.2966 70.5248 66.2347 69.732 67.0478C68.6907 68.1152 68.332 69.6291 67.5748 70.8966C67.1561 71.5962 66.7874 72.3061 66.1309 72.7936Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M99.1334 74.7407C98.9677 75.3061 98.8679 75.6462 98.7159 76.1647C98.9685 75.9692 99.1226 75.91 99.169 75.8036C99.5459 74.942 100.238 75.1649 100.784 75.4436C101.368 75.7418 101.52 76.4153 101.286 76.996C100.905 77.9437 100.464 78.8823 99.9266 79.7492C99.1848 80.9455 98.3456 82.1015 97.8403 83.4079C97.3122 84.7723 96.5422 86.0998 96.8714 87.704C97.0272 88.4611 96.3947 88.906 95.703 88.5962C95.3273 88.4272 94.9893 88.4462 94.6178 88.4649C92.2635 88.5825 91.0478 86.9129 91.9623 84.665C93.2517 81.4968 95.3327 78.8185 97.4998 76.2136C97.914 75.7158 98.1994 75.0534 99.1334 74.7407Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3097 124.22C13.2956 125.066 14.0093 125.477 14.1079 126.152C13.0649 126.294 12.4076 125.729 12.1794 124.564C11.9317 123.299 12.3272 122.134 12.9427 121.126C14.2525 118.98 15.3455 116.711 17.1751 114.858C17.8908 114.133 18.3858 112.96 19.082 112.083C21.0665 109.582 23.1379 107.15 25.1841 104.698C25.5949 104.205 26.0825 103.792 26.6449 103.471C26.9328 103.306 27.2165 103.269 27.4845 103.484C27.7724 103.716 27.7405 104.012 27.5798 104.297C27.3408 104.722 27.1262 105.17 26.8276 105.55C24.0628 109.062 21.223 112.519 18.5361 116.089C17.4894 117.481 16.6722 119.079 16.1089 120.75C15.8488 121.521 16.1275 122.368 16.2158 123.179C16.2394 123.4 16.548 123.701 16.1569 123.867C15.406 124.186 14.7768 124.501 15.4375 125.479C15.5878 125.702 15.2461 125.819 15.0084 125.825C14.5834 125.835 14.0942 125.828 14.0441 125.3C13.9985 124.815 13.7239 124.569 13.3097 124.22Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.3134 66.5308C31.0698 66.3581 30.9331 66.325 30.8267 66.5838C30.439 67.527 30.1159 68.5099 29.6098 69.3859C29.3037 69.9152 29.3695 71.0071 28.1973 70.6886C28.0109 70.638 28.049 70.8729 28.1335 71.0058C28.3311 71.3165 28.2085 71.5443 27.9944 71.8057C27.629 72.2509 27.5284 72.7451 27.6233 73.3556C27.7993 74.4831 27.0148 75.0327 25.9834 74.57C25.8596 74.5141 25.6396 74.3261 25.6285 74.4039C25.365 76.2413 24.3693 73.805 23.963 74.6951C23.6581 74.4416 23.5148 74.119 23.48 73.7205C23.4005 72.8072 23.7741 72.0347 24.1978 71.2763C25.8033 68.403 28.1029 66.1096 30.4614 63.8774C31.0292 63.3394 31.885 63.3104 32.621 63.0938C33.053 62.9666 33.3077 63.3245 33.4714 63.6666C33.6954 64.1355 33.4486 64.1844 33.065 64.0593C33.0141 64.9005 32.464 65.4829 32.0125 66.1216C31.8137 66.4028 31.6849 66.7429 31.3134 66.5308Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64.8875 20.344C64.7947 20.2752 64.7023 20.2065 64.61 20.1377C64.0773 20.9814 63.5397 21.8218 63.0198 22.6734C62.9594 22.7728 62.8409 22.9522 63.0207 23.0607C63.1458 23.1361 63.3603 23.1576 63.403 22.9994C63.6735 21.9908 64.6199 21.3492 64.8875 20.344ZM60.9622 30.5857C60.6329 31.0616 60.8678 31.8751 59.8302 31.9405C59.0801 31.9881 59.2155 31.6257 59.2151 31.2397C58.5702 31.2977 58.8283 32.0639 58.2571 32.216C58.0218 31.1792 58.4041 30.1868 58.3577 29.1906C58.3648 29.1612 58.3693 29.1289 58.3722 29.0941C59.1733 28.4065 59.358 27.3777 59.802 26.4938C59.9851 26.1284 60.0493 25.6927 60.2742 25.3618C62.0843 22.6989 63.5572 19.8235 65.5068 17.2434C67.0348 15.2213 68.388 13.0683 70.02 11.1203C70.3952 10.6725 70.727 10.1817 71.5322 9.7124C70.9109 10.8374 70.4379 11.6931 69.9653 12.5489C69.9769 12.5149 69.9872 12.4801 69.9964 12.4441C68.7509 13.4303 68.2435 14.9529 67.1769 16.0894C67.5414 16.398 67.6507 16.0762 67.8259 16.0397C68.1258 15.978 68.311 16.1661 68.427 16.4241C68.4841 16.5504 68.598 16.7368 68.3963 16.7886C67.5016 17.0185 67.7162 17.7607 67.6901 18.3493C67.6611 19 67.3074 19.3575 66.7987 19.6483C66.0151 20.096 65.5491 20.731 65.6332 21.6791C65.4957 21.8825 65.3586 22.0862 65.221 22.2896C64.3645 22.7096 63.1393 24.7554 63.2262 25.6198C62.7375 25.6451 62.5445 26.5712 61.8793 26.0634C61.6821 25.5158 61.5703 25.2565 61.0583 25.9383C60.4113 26.799 60.5153 27.9712 59.7349 28.7404C59.619 28.8543 59.6268 29.0225 59.7859 29.1198C59.9926 29.2465 60.1844 29.1538 60.2158 28.9508C60.3533 28.0735 61.0538 27.4568 61.2658 26.6222C61.3557 26.2701 61.4506 25.9619 61.9107 26.0315C62.1903 27.6573 60.9386 28.7677 60.5095 30.1557C60.4528 30.3396 59.7631 29.8442 60.0133 30.4631C60.1351 30.765 60.5944 30.9149 60.9622 30.5857Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M86.5817 105.677C86.3522 106.309 86.5299 106.88 86.7321 107.428C86.8385 107.716 86.9189 107.935 86.5734 108.055C86.2582 108.164 86.0644 107.942 85.9525 107.702C85.6671 107.089 85.738 106.405 85.9074 105.821C86.5531 103.59 87.7638 101.653 89.257 99.8877C89.7002 99.3642 90.2254 98.8899 90.5725 98.3088C91.2034 97.2526 92.277 96.9514 93.3005 96.6176C93.7785 96.4614 93.87 97.1026 93.9682 97.4476C94.0241 97.6452 93.8787 98.0284 93.7027 98.1435C92.0015 99.2561 91.7277 101.329 90.5738 102.82C90.1935 103.311 90.3679 104.307 89.8767 104.959C89.4886 105.474 89.2794 106.119 88.5579 106.364C88.1416 106.505 88.4568 107.041 88.2687 107.36C88.1722 107.524 88.113 107.72 87.9076 107.747C87.7029 107.773 87.4449 107.598 87.4818 107.468C87.7299 106.588 86.6915 106.364 86.5817 105.677Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.7828 82.9958C43.731 83.1171 43.6792 83.2381 43.6283 83.3582C42.1343 84.6749 40.9289 86.2626 39.7592 87.8428C38.1501 90.0165 36.695 92.3124 35.2937 94.6286C34.1079 96.589 32.5952 98.3506 31.7635 100.534C31.4259 101.421 30.8659 102.23 29.8884 102.835C29.6581 101.5 30.508 100.645 30.998 99.7047C32.2887 97.2257 33.919 94.9542 35.5224 92.6744C38.4151 88.5618 41.1327 84.3096 44.7379 80.7454C44.9442 80.5416 45.109 80.2612 45.6053 80.4128C45.2814 81.4922 44.2458 82.0589 43.7828 82.9958Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M87.5649 67.2491C87.1213 67.5742 87.644 68.4523 86.9648 68.3094C86.3857 68.1872 86.5949 67.481 86.7403 67.0328C87.6428 64.2577 89.4218 62.2041 91.9977 60.8592C92.3879 60.6554 92.9541 60.3443 93.3149 60.9213C93.6355 61.4328 93.1397 61.7439 92.8568 62.0641C91.6709 63.4078 90.5459 64.7796 90.2498 66.6315C90.1756 67.0966 89.7983 67.2644 89.4139 67.4218C89.2797 67.4769 89.1447 67.5668 89.0494 67.6749C88.2968 68.5306 87.729 68.6209 87.5649 67.2491Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64.3402 17.4521C64.2159 18.8343 63.0342 19.5973 62.3918 20.6734C61.9158 21.4703 61.5277 22.3745 60.7673 23.0074C60.578 23.1648 60.5022 23.5119 60.1899 23.3363C59.9413 23.1963 59.8908 22.913 60.0586 22.6466C61.0688 21.0449 62.0513 19.4237 63.2583 17.9571C63.5432 17.6108 63.9926 17.6203 64.3725 17.4795L64.3402 17.4521Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64.3725 17.4797C64.2312 17.1736 64.0672 16.8891 64.324 16.5399C65.7791 14.5596 67.2039 12.5561 68.8906 10.7589C69.021 10.6197 69.0931 10.29 69.388 10.4963C69.5748 10.6272 69.5284 10.8297 69.4005 10.9917C67.7515 13.0763 66.1515 15.1966 64.6537 17.3936C64.6115 17.4553 64.4474 17.4346 64.3402 17.4524L64.3725 17.4797Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M58.3721 29.0937C58.3697 29.1285 58.3647 29.1613 58.3581 29.1907C58.1588 29.6947 57.7239 30.1027 57.7612 30.705C57.7744 30.9195 57.803 31.2244 57.4762 31.1962C57.1842 31.171 57.2236 30.8997 57.2492 30.6685C57.4783 28.6137 58.1766 26.6922 58.9549 24.7997C59.0887 24.475 59.4801 24.1262 59.8376 24.2927C60.2609 24.4899 59.9411 24.9082 59.7912 25.1534C59.0452 26.3733 58.7201 27.7356 58.3721 29.0937Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M69.9967 12.4442C69.9876 12.4798 69.9772 12.5146 69.9652 12.5486C70.4415 12.0644 71.2703 11.921 71.3507 11.028C71.3639 10.8806 71.7392 10.3413 72.1186 10.6739C72.4794 10.9899 72.0942 11.3238 71.8854 11.5213C70.9419 12.4148 70.193 13.4242 69.7643 14.7795C69.4019 14.3561 68.8605 14.0799 69.5812 13.5658C69.9685 13.2896 69.8136 12.8021 69.9967 12.4442Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M65.2209 22.2896C65.3584 22.0862 65.4959 21.8824 65.633 21.6791C65.7983 21.549 65.9636 21.4194 66.1284 21.2893C66.1868 21.3295 66.2444 21.3697 66.3024 21.4098C65.9226 22.0879 65.5423 22.7655 65.1625 23.4436C65.0813 23.4171 65.0001 23.3901 64.9189 23.3632C65.0196 23.0058 65.1202 22.6475 65.2209 22.2896Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M61.402 30.0002C61.1809 29.222 61.7479 28.7054 62.0453 28.1053C62.3369 27.5167 62.6765 26.9517 62.9955 26.376C63.1081 26.6688 63.0816 26.9281 62.9184 27.2147C62.3953 28.1334 61.9049 29.0704 61.402 30.0002Z"
        fill="black"
      />
    </svg>
  );
};
export default Lines;
