import React from "react";
import { Route } from "react-router";
import { Routes } from "react-router-dom";
import { Businesses } from "./templates/businesses";
import { Individuals } from "./templates/individuals";
import "antd/dist/antd.css";
import "aos/dist/aos.css";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Businesses />} />
        <Route path="/individuals" element={<Individuals />} />
      </Routes>
    </div>
  );
}

export default App;
