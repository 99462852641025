const UserFriendliness = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6063 10.4529C15.6063 8.27639 17.3707 6.51202 19.5471 6.51202C21.7236 6.51202 23.4879 8.27639 23.4879 10.4529C23.4879 12.6293 21.7236 14.3937 19.5471 14.3937C17.3707 14.3937 15.6063 12.6293 15.6063 10.4529ZM19.5471 8.33087C18.3752 8.33087 17.4251 9.28091 17.4251 10.4529C17.4251 11.6248 18.3752 12.5748 19.5471 12.5748C20.7191 12.5748 21.6691 11.6248 21.6691 10.4529C21.6691 9.28091 20.7191 8.33087 19.5471 8.33087ZM28.0776 21.6348C28.3382 21.0159 28.5492 20.3704 28.7058 19.7029C29.4314 16.6096 29.4314 13.3904 28.7058 10.2971C27.658 5.82999 24.17 2.34203 19.7029 1.29419C16.6096 0.568603 13.3904 0.568604 10.2971 1.29419C5.82999 2.34203 2.34203 5.82999 1.29419 10.2971C0.568603 13.3904 0.568603 16.6096 1.29419 19.7029C1.37113 20.0309 1.46122 20.3536 1.56388 20.6705L2.51988 19.7146C5.97749 16.2577 11.5833 16.2577 15.0409 19.7146L17.7178 22.391L18.4196 21.6894C21.1083 19.0012 25.4243 19.0327 28.0776 21.6348ZM27.1933 23.3602L27.1544 23.3135C25.2538 21.0331 21.8049 20.8767 19.7056 22.9757L19.0041 23.677L22.8251 27.4974C24.5919 26.5126 26.0822 25.0995 27.159 23.3948C27.1707 23.3835 27.1822 23.3719 27.1933 23.3602ZM21.0585 28.3031L13.7549 21.0009C11.0076 18.2541 6.55321 18.2541 3.80587 21.0009L2.32059 22.4859C3.9194 25.5828 6.80991 27.8878 10.2971 28.7058C13.3904 29.4314 16.6096 29.4314 19.7029 28.7058C20.1658 28.5972 20.6182 28.4624 21.0585 28.3031Z"
        fill="url(#paint0_linear_439_9)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_439_9"
          x1="31.9633"
          y1="33.8774"
          x2="-5.89626"
          y2="-7.91715"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#01E285" />
          <stop offset="1" stopColor="#09CEAD" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default UserFriendliness;
