const FreeAccount = () => {
  return (
    <svg
      width="25"
      height="28"
      viewBox="0 0 25 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.81522 10.4839C4.04382 9.83871 4.95618 9.83871 5.18478 10.4839L5.80987 12.248C5.88304 12.4545 6.0455 12.617 6.25201 12.6901L8.01614 13.3152C8.66129 13.5438 8.66129 14.4562 8.01614 14.6848L6.25201 15.3099C6.0455 15.383 5.88304 15.5455 5.80987 15.752L5.18478 17.5161C4.95618 18.1613 4.04382 18.1613 3.81522 17.5161L3.19013 15.752C3.11696 15.5455 2.9545 15.383 2.74799 15.3099L0.983857 14.6848C0.338715 14.4562 0.338714 13.5438 0.983856 13.3152L2.74799 12.6901C2.9545 12.617 3.11696 12.4545 3.19013 12.248L3.81522 10.4839Z"
        fill="url(#paint0_linear_439_4)"
      />
      <path
        d="M13.8152 20.4839C14.0438 19.8387 14.9562 19.8387 15.1848 20.4839L15.8099 22.248C15.883 22.4545 16.0455 22.617 16.252 22.6901L18.0161 23.3152C18.6613 23.5438 18.6613 24.4562 18.0161 24.6848L16.252 25.3099C16.0455 25.383 15.883 25.5455 15.8099 25.752L15.1848 27.5161C14.9562 28.1613 14.0438 28.1613 13.8152 27.5161L13.1901 25.752C13.117 25.5455 12.9545 25.383 12.748 25.3099L10.9839 24.6848C10.3387 24.4562 10.3387 23.5438 10.9839 23.3152L12.748 22.6901C12.9545 22.617 13.117 22.4545 13.1901 22.248L13.8152 20.4839Z"
        fill="url(#paint1_linear_439_4)"
      />
      <path
        d="M16.4592 0.735438C16.8066 -0.245146 18.1934 -0.245146 18.5408 0.735438L19.7317 4.09628C19.8429 4.41016 20.0898 4.65709 20.4037 4.76831L23.7646 5.95917C24.7451 6.30662 24.7451 7.69338 23.7646 8.04083L20.4037 9.23169C20.0898 9.34291 19.8429 9.58984 19.7317 9.90372L18.5408 13.2646C18.1934 14.2451 16.8066 14.2451 16.4592 13.2646L15.2683 9.90372C15.1571 9.58984 14.9102 9.34291 14.5963 9.23169L11.2354 8.04083C10.2549 7.69338 10.2549 6.30662 11.2354 5.95917L14.5963 4.76831C14.9102 4.65709 15.1571 4.41016 15.2683 4.09628L16.4592 0.735438Z"
        fill="url(#paint2_linear_439_4)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_439_4"
          x1="26.7849"
          y1="32.5462"
          x2="-10.5354"
          y2="-2.76745"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#01E285" />
          <stop offset="1" stopColor="#09CEAD" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_439_4"
          x1="26.7849"
          y1="32.5462"
          x2="-10.5354"
          y2="-2.76745"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#01E285" />
          <stop offset="1" stopColor="#09CEAD" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_439_4"
          x1="26.7849"
          y1="32.5462"
          x2="-10.5354"
          y2="-2.76745"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#01E285" />
          <stop offset="1" stopColor="#09CEAD" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default FreeAccount
