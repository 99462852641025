const AccessAnywhere = () => {
  return (
    <svg
      width="26"
      height="30"
      viewBox="0 0 26 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9999 0.75C5.98222 0.75 0.333252 6.61296 0.333252 13.7949C0.333252 17.3604 1.72388 21.1801 3.94874 24.1152C6.16851 27.0437 9.34934 29.25 12.9999 29.25C16.6505 29.25 19.8313 27.0437 22.0511 24.1152C24.276 21.1801 25.6666 17.3604 25.6666 13.7949C25.6666 6.61296 20.0176 0.75 12.9999 0.75ZM8.5123 12.8077C8.5123 10.3054 10.5215 8.27692 12.9999 8.27692C15.4784 8.27692 17.4875 10.3054 17.4875 12.8077C17.4875 15.31 15.4784 17.3385 12.9999 17.3385C10.5215 17.3385 8.5123 15.31 8.5123 12.8077ZM12.9999 10.0308C11.4809 10.0308 10.2494 11.274 10.2494 12.8077C10.2494 14.3413 11.4809 15.5846 12.9999 15.5846C14.519 15.5846 15.7504 14.3413 15.7504 12.8077C15.7504 11.274 14.519 10.0308 12.9999 10.0308Z"
        fill="url(#paint0_linear_439_6)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_439_6"
          x1="28.0784"
          y1="33.8774"
          x2="-9.9596"
          y2="-3.44841"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#01E285" />
          <stop offset="1" stopColor="#09CEAD" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default AccessAnywhere;
