import { FC } from "react";
import styles from "./index.module.scss";

type buttonProps = {
  text: string;
  onClick?: () => void;
};
export const PrimaryButton: FC<buttonProps> = ({ text, onClick }) => {
  return (
    <button className={`${styles.primary} text-white`} onClick={onClick}>
      {text}
    </button>
  );
};
