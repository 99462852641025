const { REACT_APP_API_ENDPOINT } = process.env


export const PostData= async (email:string) => {
  const url = `${REACT_APP_API_ENDPOINT}/joinUs`
  const data = {
    "email":email
  }
  try {
    await fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
      mode: 'cors',
    })
  } catch (error) {
    console.error('Ошибка:', error)
  }
}