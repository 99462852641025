const FinextLogo = () => {
  return (
    <svg
      width="138"
      height="138"
      viewBox="0 0 138 138"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M137.2 28.144C137.2 27.6479 137.2 27.152 137.2 26.6559C136.798 24.8517 136.278 23.0759 135.643 21.3404C133.205 15.0749 128.933 9.69285 123.388 5.89894C117.844 2.10503 111.285 0.0761587 104.569 0.0781264H33.4296C24.5996 0.0781065 16.1304 3.58528 9.88006 9.83024C3.62967 16.0752 0.108835 24.5475 0.0900879 33.3886V104.688C0.090078 109.069 0.952394 113.406 2.62874 117.452C4.30509 121.498 6.76251 125.173 9.85932 128.268C12.9561 131.362 16.6313 133.814 20.6758 135.484C24.7204 137.154 29.0547 138.008 33.4296 137.999H104.427C108.799 138.008 113.128 137.153 117.169 135.482C121.209 133.811 124.882 131.358 127.973 128.263C131.064 125.168 133.514 121.493 135.183 117.447C136.852 113.401 137.705 109.065 137.696 104.688V33.3886C137.639 31.6318 137.473 29.8803 137.2 28.144Z"
        fill="url(#paint0_linear_86_484)"
      />
      <path
        d="M137.2 28.144L129.344 52.5952H68.326C65.4384 52.5952 62.5803 52.0254 59.9134 50.9168C57.2464 49.8082 54.8233 48.1827 52.7847 46.135C50.7462 44.0873 49.1326 41.6574 48.034 38.9836C46.9355 36.3098 46.3743 33.4447 46.3837 30.5535V21.3403H135.361C135.996 23.0759 136.516 24.8516 136.918 26.6559C136.989 27.152 137.129 27.6479 137.2 28.144Z"
        fill="#F7F7F7"
      />
      <path
        d="M109.311 59.8957L108.321 62.7308L99.2601 89.5214H59.2672C58.4297 89.5176 57.6105 89.7742 56.9256 90.2568C56.2407 90.7394 55.7237 91.4241 55.4446 92.2147L46.4957 116.666H17.503H15.0957L27.6248 82.0793C29.9792 75.5662 34.2788 69.9367 39.9396 65.9553C45.6004 61.9738 52.3494 59.8332 59.2672 59.8247L109.311 59.8957Z"
        fill="#F7F7F7"
      />
      <path
        style={{ mixBlendMode: "multiply" }}
        d="M108.32 62.7307L99.2598 89.5213H59.2669C58.4294 89.5175 57.6102 89.7741 56.9253 90.2567C56.2403 90.7393 55.7234 91.424 55.4442 92.2146L46.4958 116.666H17.5027L29.0415 84.9144C31.4163 78.4202 35.7227 72.8116 41.3805 68.8448C47.0384 64.8781 53.7753 62.7441 60.6823 62.7307H108.32Z"
        fill="url(#paint1_linear_86_484)"
      />
      <path
        style={{ mixBlendMode: "multiply" }}
        d="M136.918 26.6559L129.273 49.4771H71.1583C68.2707 49.4771 65.4108 48.9064 62.7439 47.7978C60.077 46.6893 57.6556 45.0647 55.617 43.0169C53.5785 40.9692 51.9632 38.5385 50.8646 35.8646C49.766 33.1908 49.2049 30.3266 49.2142 27.4354V21.0565H135.361C136.009 22.8846 136.529 24.7557 136.918 26.6559Z"
        fill="url(#paint2_linear_86_484)"
      />
      <path
        d="M81.0669 54.7927L81.7046 39.6965C81.7195 39.3909 81.8245 39.0961 82.0053 38.8495C82.1862 38.6028 82.434 38.4154 82.7208 38.3096C83.0075 38.2038 83.3202 38.1849 83.6177 38.2551C83.9151 38.3253 84.1842 38.4812 84.3936 38.7041L90.9779 45.7916L99.0466 54.438C99.3407 54.742 99.5046 55.149 99.5046 55.5722C99.5046 55.9955 99.3407 56.4024 99.0466 56.7064L90.1985 64.5734L83.12 71.0941C82.8899 71.3112 82.5995 71.4529 82.287 71.5007C81.9745 71.5485 81.6553 71.4999 81.3711 71.3614C81.0868 71.223 80.8507 71.0012 80.6954 70.7255C80.54 70.4499 80.4732 70.1332 80.5018 69.818L81.0669 54.7927Z"
        fill="url(#paint3_linear_86_484)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_86_484"
          x1="-0.193051"
          y1="69.0385"
          x2="137.554"
          y2="69.0385"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#16C37B" />
          <stop offset="1" stopColor="#17BDA1" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_86_484"
          x1="77.7411"
          y1="114.54"
          x2="44.1979"
          y2="58.5152"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.34" stopColor="white" stopOpacity="0" />
          <stop offset="1" stopOpacity="0.2" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_86_484"
          x1="111.506"
          y1="24.6711"
          x2="53.1507"
          y2="52.822"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.34" stopColor="white" stopOpacity="0" />
          <stop offset="0.96" stopOpacity="0.2" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_86_484"
          x1="80.3602"
          y1="54.8638"
          x2="99.4708"
          y2="54.8638"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#16C37B" />
          <stop offset="1" stopColor="#17BDA1" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default FinextLogo;
