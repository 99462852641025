import React, { useEffect } from "react";
import styles from "./index.module.scss";
import { statusChart, confirmByCfo, managementBusiness } from "assets/images";
import totalBalance from "assets/images/total-balance.jpg";
import { ContentTemplate } from "custom/contentTemplate";
import Aos from "aos";

export const MasterBusiness = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  const htmlEl = "Keep them and transfer them anywhere worldwide with your Finext global fully, <strong>regulated</strong> account․ Manage your company payments, with our unique control and monitoring module";
  return (
    <section
      className={`${styles["master-business"]} flex flex-wrap justify-between pt-16 md:pt-28`}
    >
      <ContentTemplate
        title="One Master Managment Business Account for All Fiat and Digital Assets"
        paragraph={htmlEl}
      />
      <div className={styles["image-cont"]}>
        <img
          src={managementBusiness}
          alt="One Master Managment Business Account for All Fiat and Digital Assets"
        />
        <div className={styles["img-1-cont"]} data-aos="fade-right">
          <img src={confirmByCfo} alt="confirm by CFOs" />
        </div>
        <div className={styles["img-2-cont"]} data-aos="fade-left">
          <img src={statusChart} alt="chart status" />
        </div>
        <div className={styles["img-3-cont"]} data-aos="fade-right">
          <img src={totalBalance} alt="total balance" />
        </div>
      </div>
    </section>
  );
};
