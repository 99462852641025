 const AssetsSafeguard = () => {
  return (
    <svg
      width="27"
      height="30"
      viewBox="0 0 27 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1342 3.14695C13.0297 2.91994 13.97 2.91994 14.8656 3.14695C16.9546 3.67651 18.4491 5.43711 18.615 7.5043L18.5531 7.49155C15.2213 6.80487 11.7786 6.80487 8.44671 7.49155L8.38474 7.50432C8.55063 5.43712 10.0451 3.67651 12.1342 3.14695ZM20.9179 7.88828V8.2208C23.252 9.30046 25.0215 11.34 25.6889 13.84C26.4762 16.7887 26.2866 19.9041 25.1474 22.7418L25.0136 23.0751C23.7916 26.119 21.0091 28.3085 17.6954 28.8337L16.617 29.0046C14.5525 29.3318 12.4473 29.3318 10.3828 29.0046L9.30439 28.8337C5.9907 28.3085 3.20823 26.119 1.9862 23.075L1.85241 22.7418C0.713182 19.9041 0.523671 16.7887 1.31091 13.84C1.97833 11.3401 3.74782 9.30052 6.0819 8.22085V7.88827C6.0819 4.64426 8.33271 1.80963 11.5577 0.992122C12.8313 0.669293 14.1685 0.669293 15.442 0.992122C18.667 1.80964 20.9179 4.64427 20.9179 7.88828ZM13.4999 16.3464C14.0053 16.3464 14.4149 16.7452 14.4149 17.2371V20.206C14.4149 20.6979 14.0053 21.0967 13.4999 21.0967C12.9946 21.0967 12.5849 20.6979 12.5849 20.206V17.2371C12.5849 16.7452 12.9946 16.3464 13.4999 16.3464Z"
        fill="url(#paint0_linear_439_10)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_439_10"
          x1="28.5784"
          y1="33.8774"
          x2="-9.4596"
          y2="-3.44841"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#01E285" />
          <stop offset="1" stopColor="#09CEAD" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default AssetsSafeguard;