import React, { FC } from "react";
import { Modal } from "antd";
import { Success } from "assets/icons";

type ModalConfirmProps = {
  isConfirmVisible: boolean;
  setIsConfirmVisible: React.Dispatch<React.SetStateAction<boolean>>;
};
export const ModalConfirm: FC<ModalConfirmProps> = ({
  isConfirmVisible,
  setIsConfirmVisible,
}) => {
  const handleOk = () => {
    setIsConfirmVisible(false);
  };

  const handleCancel = () => {
    setIsConfirmVisible(false);
  };

  return (
    <div>
      <Modal
        title="Thank you for your interest in Finext Global!"
        visible={isConfirmVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Back"
      >
        <Success />
        <p className="font-bold pt-4">Email Submitted!</p>
      </Modal>
    </div>
  );
};
