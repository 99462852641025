import React, { FC } from "react";
import styles from "./index.module.scss";
import { Lines } from "assets/icons";

type ChooseFinextWrapperProps = {
  children: React.ReactNode;
};

export const ChooseFinextWrapper: FC<ChooseFinextWrapperProps> = ({
  children,
}) => {
  return (
    <div className={`${styles["choose-cont"]} bg-white mt-42`}>
      <h2>Why Choose Finext Global</h2>
      <div className={styles["lines-cont"]}>
        <Lines />
      </div>
      {children}
    </div>
  );
};
