import React, { FC, useState } from "react";
import { Modal } from "antd";
import { PrimaryButton } from "../../buttons/primary";
import styles from "./index.module.scss";
import { ModalConfirm } from "../modalConfirm";
import { emailCheck } from "utils/const/regex";
import { PostData } from "api/dal";

type ModalSubmit = {
  buttonText: string;
};
export const ModalSubmit: FC<ModalSubmit> = ({ buttonText }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [emailValue, setEmailValue] = useState("");
  const [error, setError] = useState(false);
  const [isConfirmVisible, setIsConfirmVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    if (emailValue.match(emailCheck)) {
      await PostData(emailValue)
      setError(false);
      setIsModalVisible(false);
      setEmailValue("");
      setIsConfirmVisible(true);
    } else {
      setError(true);
    }
  };
  const handleCancel = () => {
    setEmailValue("");
    setIsModalVisible(false);
    setError(false);
  };

  const loginSubmit = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmailValue(e.target.value);
    if (emailValue.match(emailCheck)) {
      setError(false);
    }
  };

  return (
    <>
      <div className={`${styles["button-cont"]}`}>
        <PrimaryButton text={buttonText} onClick={showModal} />
      </div>
      <Modal
        title="Thank you for your interest in Finext Global!"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Submit"
      >
        <p className="font-bold pt-4">
          Our International Support team will be in touch with you within two
          business days.
        </p>
        <input
          className={styles["email"]}
          type="email"
          placeholder="Enter your email here"
          onChange={(e) => loginSubmit(e)}
          value={emailValue}
        />

        <div className="text-red-600 h-5">
          {error && "Please provide a valid email*"}
        </div>
      </Modal>
      {isConfirmVisible && (
        <ModalConfirm
          isConfirmVisible={isConfirmVisible}
          setIsConfirmVisible={setIsConfirmVisible}
        />
      )}
    </>
  );
};
