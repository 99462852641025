import React, { FC } from "react";
import styles from "./index.module.scss";

type ChooseItemProps = {
  text: string;
  children: React.ReactNode;
};
export const ChooseItem: FC<ChooseItemProps> = ({ text, children }) => {
  return (
    <div className={styles["choseItem-cont"]}>
      <div className={styles["icon-cont"]}>
        <div className={styles["icon-cont"]}>{children}</div>
        <h4>{text}</h4>
      </div>
    </div>
  );
};
